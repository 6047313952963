import {lazy, Suspense} from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import { LoadingDashBoard } from '../components/loading-component/LoadingDashBoard';

//test
const HomePage = lazy(() => import('../pages/home/HomePage'));
const SignPage = lazy(() => import('../pages/sign/SignPage'));
const SignUpSuccessPage = lazy(() => import('../pages/sign-up-success/SignUpSuccessPage'));
const ServerErrorPage = lazy(() => import('../pages/server-error/ServerErrorPage'));
const ProjectsPage = lazy(() => import('../pages/projects-list/ProjectsListPage'));
const PropertyPage = lazy(() => import('../pages/property/PropertyPage'));
const ProjectPage = lazy(() => import('../pages/project/ProjectPage'));
const DeveloperPage = lazy(() => import('../pages/developer/DeveloperPage'));
const PropertiesListPage = lazy(() => import('../pages/properties-list/PropertiesListPage'));
const LeadsPage = lazy(() => import('../pages/leads/LeadsPage'));

const Routes = () => (
	<Suspense fallback={<LoadingDashBoard/>}>
		<Switch>
			{/*<Route exact path='/' component={HomePage}/>*/}
			<Route exact path='/'>
				<Redirect to={'/developer'}/>
			</Route>
			<Route exact path='/sign' component={ SignPage }/>
			<Route exact path='/sign-up-success' component={ SignUpSuccessPage }/>
			<Route exact path='/server-error' component={ ServerErrorPage }/>
			<Route exact path='/projects' component={ ProjectsPage }/>
			<Route exact path='/properties' component={ PropertiesListPage }/>
			<Route exact path='/developer' component={ DeveloperPage }/>
			<Route exact path='/property/:id' component={ PropertyPage }/>
			<Route exact path='/project/:id' component={ ProjectPage }/>
			<Route exact path='/leads' component={ LeadsPage }/>
		</Switch>
	</Suspense>
);

export default Routes;