import styled, {css} from "styled-components";
import {MEDIA_DIAMOND, MEDIA_GOLD, MEDIA_PLATINUM, MEDIA_SILVER} from "../../styles/responsive";
import {ComponentPropsWithRef} from "react";
import { InputText } from "../form-components/inputs/InputText";
import MobileIcon from "../icons/MobileIcon";
import SecurityIcon from "../icons/SecurityIcon";
import { ButtonMain } from "../form-components/buttons/ButtonMain";
import { useDispatch } from "react-redux";
import { SubmitHandler, useForm } from "react-hook-form";
import { Api } from "../../api/vuSpaceApi";
import { ObjectToString, StringToObject } from "../../common/constants";
import { setUserAction } from "../../store/actions";
import { useHistory } from "react-router-dom";
import EmailIcon from "../icons/EmailIcon";

const Bronze = css`
& input {
    height: 52px;
    margin-bottom: 10px;
}
& .vs-sign-in-button{
    margin-top: 20px;
}

[dir = 'ltr'] & {

}

[dir = 'rtl'] & {

}

`

const Silver = css`

`

const Gold = css`

`

const Platinum = css`

`

const Diamond = css`

`
type signInFormProps = {} & ComponentPropsWithRef<"div">;

type SingInForm = {
    emailOrPhone:string,
    password:string,
}

const SSignInForm = styled.div`
${Bronze}
${MEDIA_SILVER`${Silver}`}
${MEDIA_GOLD`${Gold}`}
${MEDIA_PLATINUM`${Platinum}`}
${MEDIA_DIAMOND`${Diamond}`}
`

export const SignInForm = ({children,...props}:signInFormProps) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {register,handleSubmit,formState:{errors},control,setValue,getValues} = useForm<SingInForm>({
        mode:'onTouched'
    });
    const submitHandler:SubmitHandler<SingInForm> = async (data:SingInForm)=>{
        await Api.users.login({
            ...data,
            type:"developer"
        }).then(user=>{
            let date = Date.now() + 43200000;
            localStorage.setItem('EXPIRY_TIME',date.toString())
            dispatch(setUserAction({name:user.name,_id:user._id,token:user.token,devId:user.assignedDeveloper}))
        });
    }
return (
<SSignInForm {...props}>
    <form onSubmit={handleSubmit(submitHandler)}>
        <InputText
            hasIcon={true}
            placeholder="Email Address"
            className="vs-input-small" 
            {...register("emailOrPhone")} 
        >
            <EmailIcon/>
        </InputText>
        <InputText
            hasIcon={true}
            placeholder="Password"
            type='password' 
            className="vs-input-small" 
            {...register("password")} 
        >
            <SecurityIcon/>
        </InputText>
        <ButtonMain variant='hollow-green' className='vs-sign-in-button'>
            SIGN IN
        </ButtonMain>
    </form>
</SSignInForm>
)
}