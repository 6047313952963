import {
	Id,
	UpdateDeveloper,
	CreateDeveloperResponse,
	UpdateDeveloperResponse,
	GetOneDeveloper,
	GetAllDevelopers,
	Feature,
	queryLang,
	createFeature,
	createFeatureResponse,
	updateFeatureResponse,
	updateFeature,
	Pagination,
	CreateBrand,
	CreateBrandResponse,
	UpdateBrand,
	UpdateBrandResponse,
	GetOneFloor,
	CreateFloor,
	CreateFloorResponse,
	GetAllFloors,
	UpdateFloor,
	UpdateFloorResponse,
	CreateDoor,
	CreateDoorResponse,
	GetAllDoors,
	GetOneDoor,
	UpdateDoor,
	CreateWall,
	CreateWallResponse,
	GetAllWalls,
	GetOneWall,
	UpdateWall,
	UpdateWallResponse,
	CreateWindow,
	CreateWindowResponse,
	GetAllWindows,
	GetOneWindow,
	UpdateWindow,
	UpdateWindowResponse,
	GetAllAmenities,
	createAmenity,
	createAmenityResponse,
	GetOneAmenity,
	updateAmenity,
	updateAmenityResponse,
	createPropertyType,
	createPropertyTypeResponse,
	GetAllPropertyTypes,
	GetOnePropertyType,
	updatePropertyType,
	updatePropertyTypeResponse,
	CreateProperty,
	CreatePropertyResponse,
	GetAllProperties,
	UpdateProperty,
	UpdatePropertyResponse,
	GetOneProperty,
	GetAllPermissions,
	Permission,
	CreateRole,
	CreateRoleResponse,
	GetAllRoles,
	GetOneRole,
	UpdateRole,
	UpdateRoleResponse,
	GetAllUsers,
	GetAllProjects,
	CreateCategory,
	CreateCategoryResponse,
	GetAllCategories,
	GetOneCategory,
	UpdateCategory,
	UpdateCategoryResponse,
	CreateSubCategory,
	CreateSubCategoryResponse,
	GetOneSubCategory,
	UpdateSubCategory,
	UpdateSubCategoryResponse,
	GetAllSubCategories,
	GetOneBrand,
	GetAllBrands,
	GetOneProject,
	CreateProject,
	CreateProjectResponse,
	UpdateProject,
	GetAllCountries,
	CreateCountry,
	CreateCountryResponse,
	GetOneCountry,
	UpdateCountry,
	UpdateCountryResponse,
	GetAllCities,
	CreateCity,
	CreateCityResponse,
	GetOneCity,
	UpdateCityResponse,
	UpdateCity,
	CreateDistrict,
	CreateDistrictResponse,
	GetOneDistrict,
	UpdateDistrict,
	UpdateDistrictResponse,
	GetAllDistricts,
	UserLoginOrRegisterResponse,
	CreateDefaultUnitArea,
	GetOneDefaultUnitAreas,
	GetAllDefaultUnitAreas,
	UpdateDefaultUnitArea,
	CreateDefaultBuildingResponse,
	CreateDefaultBuilding,
	UpdateDefaultBuilding,
	GetAllDefaultBuildings,
	GetOneDefaultBuilding, GetAllContacts
} from './../types/types';
import axios from "axios";
import {API_BASE_URL, ENDPOINTS} from "../common/constants";
import {CreateDeveloper, language} from "../types/types";


const caching = false;
const websiteApi = axios.create({
	baseURL: API_BASE_URL,
})

// const getCached = async (endpoint:string, config?:object) => {
// 	let data = caching && localStorage.getItem(`${endpoint}/${JSON.stringify(config)}`);
// 	if (data) {
// 		data = JSON.parse(data);
// 	} else {
// 		data = await websiteApi(endpoint, config).then((res) => res.data);
// 		caching && localStorage.setItem(`${endpoint}/${JSON.stringify(config)}`, JSON.stringify(data));
// 	}
// 	return data;
// }

type DeleteResponse = {
	success:boolean,
	message:string
}
export const Api = {
	users:{
		// async login(loginData:{emailOrPhone:string,password:string,type:string}):Promise<UserLoginResponse> {
		// 	const response = await websiteApi.post(ENDPOINTS.LOGIN,loginData)
		// 	return response.data
		// },
		// async getAll(token:string,pagination:Pagination={pagination:false}):Promise<GetAllUsers>{
		// 	const response = await websiteApi.get(`${ENDPOINTS.USERS}`,{headers:{Authorization:token}})
		// 	return response.data;
		// }
		async login(loginData:{emailOrPhone:string,password:string,type:string}):Promise<UserLoginOrRegisterResponse> {
			const response = await websiteApi.post(ENDPOINTS.LOGIN,loginData);
			console.log(response);
			return response.data
		},
		async register(signUpData:{name:string,email:string,phone:string,password:string,confirmPassword:string}):Promise<UserLoginOrRegisterResponse> {
			const response = await websiteApi.post(ENDPOINTS.REGISTER,signUpData);
			return response.data
		},
		async update(token:string,updateData:{favoriteDevelopers:Id[],favoriteProjects:Id[],favoriteProperties:Id[]},userId:Id):Promise<UserLoginOrRegisterResponse> {
			const response = await websiteApi.put(`${ENDPOINTS.USERS}/${userId}`,updateData,{headers:{Authorization:token}});
			console.log(response.data)
			return response.data
		},
		async getAll(token:string,pagination:Pagination={pagination:false}):Promise<GetAllUsers>{
			const response = await websiteApi.get(`${ENDPOINTS.USERS}`,{headers:{Authorization:token}})
			return response.data;
		}, 
	},
	contacts:{
		async getAll(token:string,userId:Id,lang:queryLang="all",pagination:Pagination={pagination:false}):Promise<GetAllContacts> {
			const response = await websiteApi.get(ENDPOINTS.CONTACTS,{headers:{Authorization:token, "content-language":lang},params:{developer:userId,...pagination}})
			return response.data;
		},
		async delete(token:string,id:Id):Promise<DeleteResponse> {
			const response = await websiteApi.delete(ENDPOINTS.CONTACTS+`/${id}`,{headers:{Authorization:token}})
			return response.data;
		},
	},
	developers:{
		async create(token:string,devData:CreateDeveloper,lang:queryLang="all"):Promise<CreateDeveloperResponse> {
			const response = await websiteApi.post(ENDPOINTS.DEVELOPERS,devData,{headers:{Authorization:token, "content-language":lang}});
			return response.data;
		},
		async update(token:string,newData:UpdateDeveloper,id:Id):Promise<UpdateDeveloperResponse> {
			const response = await websiteApi.put(ENDPOINTS.DEVELOPERS+`/${id}`,newData,{headers:{Authorization:token, "content-language":'all'}})
			return response.data;
		},
		async delete(token:string,id:Id):Promise<DeleteResponse> {
			const response = await websiteApi.delete(ENDPOINTS.DEVELOPERS+`/${id}`,{headers:{Authorization:token}})
			return response.data;
		},
		async getOne(token:string,id:Id,lang:queryLang="all"):Promise<GetOneDeveloper> {
			const response = await websiteApi.get(ENDPOINTS.DEVELOPERS+`/${id}`,{headers:{Authorization:token, "content-language":lang}})
			return response.data;
		},
		async getAll(token:string,lang:queryLang="all",pagination:Pagination={pagination:false}):Promise<GetAllDevelopers> {
			const response = await websiteApi.get(ENDPOINTS.DEVELOPERS,{headers:{Authorization:token, "content-language":lang},params:{...pagination}})
			return response.data;
		},
	},
	projects:{
		async create(token:string,projectData:CreateProject):Promise<CreateProjectResponse>{
			const response = await websiteApi.post(ENDPOINTS.PROJECTS,projectData,{headers:{Authorization: token}});
			return response.data;
		},
		async getAll(token:string,lang:queryLang="all"):Promise<GetAllProjects>{
			const response = await websiteApi.get(ENDPOINTS.PROJECTS,{headers:{Authorization: token,'content-language':lang}});
			return response.data;
		},
		async getOne(token:string,id:Id,lang:queryLang="all"):Promise<GetOneProject> {
			const response = await websiteApi.get(ENDPOINTS.PROJECTS+`/${id}`,{headers:{Authorization:token,'content-language':lang}})
			return response.data;
		},
		async update(token:string,newData:UpdateProject,id:Id,lang:queryLang="all"):Promise<GetOneProject>{
			const response = await websiteApi.put(`${ENDPOINTS.PROJECTS}/${id}`,newData,{headers:{Authorization:token,'content-language':lang}});
			return response.data;
		},
		async delete(token:string,id:Id):Promise<DeleteResponse>{
			const response = await websiteApi.delete(`${ENDPOINTS.PROJECTS}/${id}`,{headers:{Authorization:token}});
			return response.data;
		}
		
	},
	buildings:{
		async create(token:string,buildingData:CreateDefaultBuilding,projectId:Id):Promise<CreateDefaultBuildingResponse>{
			const response = await websiteApi.post(`${ENDPOINTS.PROJECTS}/${projectId}${ENDPOINTS.BUILDINGS}`,buildingData,{headers:{Authorization: token}});
			return response.data;
		},
		async getAll(token:string,projectId:string,lang:queryLang="all"):Promise<GetAllDefaultBuildings>{
			const response = await websiteApi.get(`${ENDPOINTS.PROJECTS}/${projectId}${ENDPOINTS.BUILDINGS}`,{headers:{Authorization: token,'content-language':lang}});
			return response.data;
		},
		async getOne(token:string,projectId:Id,buildingId:Id,lang:queryLang="all"):Promise<GetOneDefaultBuilding> {
			const response = await websiteApi.get(`${ENDPOINTS.PROJECTS}/${projectId}${ENDPOINTS.BUILDINGS}/${buildingId}`,{headers:{Authorization:token,'content-language':lang}})
			return response.data;
		},
		async update(token:string,buildingData:UpdateDefaultBuilding,projectId:Id,buildingId:Id,lang:queryLang="all"):Promise<GetOneDefaultBuilding>{
			const response = await websiteApi.put(`${ENDPOINTS.PROJECTS}/${projectId}${ENDPOINTS.BUILDINGS}/${buildingId}`,buildingData,{headers:{Authorization:token,'content-language':lang}});
			return response.data;
		},
		async delete(token:string,projectId:Id,buildingId:Id):Promise<DeleteResponse>{
			const response = await websiteApi.delete(`${ENDPOINTS.PROJECTS}/${projectId}${ENDPOINTS.BUILDINGS}/${buildingId}`,{headers:{Authorization:token}});
			return response.data;
		}
	},
	unitAreas:{
		async create(token:string,projectId:Id,unitAreaData:CreateDefaultUnitArea):Promise<GetOneDefaultUnitAreas>{
			const response = await websiteApi.post(`${ENDPOINTS.PROJECTS}/${projectId}${ENDPOINTS.UNIT_AREAS}`,unitAreaData,{headers:{Authorization: token}});
			return response.data;
		},
		async getAll(token:string,projectId:Id,lang:queryLang="all"):Promise<GetAllDefaultUnitAreas>{
			const response = await websiteApi.get(`${ENDPOINTS.PROJECTS}/${projectId}${ENDPOINTS.UNIT_AREAS}`,{headers:{Authorization: token,'content-language':lang}});
			return response.data;
		},
		async getOne(token:string,projectId:Id,id:Id,lang:queryLang="all"):Promise<GetOneDefaultUnitAreas> {
			const response = await websiteApi.get(`${ENDPOINTS.PROJECTS}/${projectId}${ENDPOINTS.UNIT_AREAS}/${id}`,{headers:{Authorization:token,'content-language':lang}})
			return response.data;
		},
		async update(token:string,projectId:Id,id:Id,newData:UpdateDefaultUnitArea,lang:queryLang="all"):Promise<GetOneDefaultUnitAreas>{
			const response = await websiteApi.put(`${ENDPOINTS.PROJECTS}/${projectId}${ENDPOINTS.UNIT_AREAS}/${id}`,newData,{headers:{Authorization:token,'content-language':lang}});
			return response.data;
		},
		async delete(token:string,projectId:Id,id:Id):Promise<DeleteResponse>{
			const response = await websiteApi.delete(`${ENDPOINTS.PROJECTS}/${projectId}${ENDPOINTS.UNIT_AREAS}/${id}`,{headers:{Authorization:token}});
			return response.data;
		}
		
	},
	features:{
		async getOne(token:string,id:Id,lang:queryLang="all"):Promise<Feature>{
			const response = await websiteApi.get(`${ENDPOINTS.FEATURES}/${id}`,{headers:{Authorization: token,'content-language': lang}});
			return response.data;
		},
		async getAll(token:string,lang:queryLang="all"):Promise<Feature[]>{
			const response = await websiteApi.get(`${ENDPOINTS.FEATURES}`,{headers:{Authorization: token,'content-language':lang}});
			return response.data;
		},
		async create(token:string,featureData:createFeature):Promise<createFeatureResponse>{
			const response = await websiteApi.post(ENDPOINTS.FEATURES,featureData,{headers:{Authorization: token, "content-language":"all"}});
			return response.data;
		},
		async delete(token:string,id:Id):Promise<DeleteResponse>{
			const response = await websiteApi.delete(`${ENDPOINTS.FEATURES}/${id}`,{headers:{Authorization:token}});
			return response.data;
		},
		async update(token:string,newData:updateFeature,id:Id,lang:queryLang="all"):Promise<updateFeatureResponse>{
			const response = await websiteApi.put(`${ENDPOINTS.FEATURES}/${id}`,newData,{headers:{Authorization:token,'content-language':lang}});
			return response.data;
		}
	},
	propertyTypes:{
		async create(token:string,propertyTypeData:createPropertyType):Promise<createPropertyTypeResponse>{
			const response = await websiteApi.post(ENDPOINTS.PROPERTY_TYPES,propertyTypeData,{headers:{Authorization: token, "content-language":"all"}});
			return response.data;
		},
		async getAll(token:string,lang:queryLang="all"):Promise<GetAllPropertyTypes>{
			const response = await websiteApi.get(`${ENDPOINTS.PROPERTY_TYPES}`,{headers:{Authorization: token,'content-language':lang}});
			return response.data;
		},
		async getOne(token:string,id:Id,lang:queryLang="all"):Promise<GetOnePropertyType>{
			const response = await websiteApi.get(`${ENDPOINTS.PROPERTY_TYPES}/${id}`,{headers:{Authorization: token,'content-language': lang}});
			return response.data;
		},
		async update(token:string,newData:updatePropertyType,id:Id,lang:queryLang="all"):Promise<updatePropertyTypeResponse>{
			const response = await websiteApi.put(`${ENDPOINTS.PROPERTY_TYPES}/${id}`,newData,{headers:{Authorization:token,'content-language':lang}});
			return response.data;
		},
		async delete(token:string,id:Id):Promise<DeleteResponse>{
			const response = await websiteApi.delete(`${ENDPOINTS.PROPERTY_TYPES}/${id}`,{headers:{Authorization:token}});
			return response.data;
		}
	},
	amenities:{
		async create(token:string,amenityData:createAmenity):Promise<createAmenityResponse>{
			const response = await websiteApi.post(ENDPOINTS.AMENITIES,amenityData,{headers:{Authorization: token, "content-language":"all"}});
			return response.data;
		},
		async getAll(token:string,lang:queryLang="all"):Promise<GetAllAmenities>{
			const response = await websiteApi.get(`${ENDPOINTS.AMENITIES}`,{headers:{Authorization: token,'content-language':lang}});
			return response.data;
		},
		async getOne(token:string,id:Id,lang:queryLang="all"):Promise<GetOneAmenity>{
			const response = await websiteApi.get(`${ENDPOINTS.AMENITIES}/${id}`,{headers:{Authorization: token,'content-language': lang}});
			return response.data;
		},
		async update(token:string,newData:updateAmenity,id:Id,lang:queryLang="all"):Promise<updateAmenityResponse>{
			const response = await websiteApi.put(`${ENDPOINTS.AMENITIES}/${id}`,newData,{headers:{Authorization:token,'content-language':lang}});
			return response.data;
		},
		async delete(token:string,id:Id):Promise<DeleteResponse>{
			const response = await websiteApi.delete(`${ENDPOINTS.AMENITIES}/${id}`,{headers:{Authorization:token}});
			return response.data;
		}
	},
	brands:{
		async getOne(token:string,id:Id,lang:queryLang="all"):Promise<GetOneBrand>{
			const response = await websiteApi.get(`${ENDPOINTS.BRANDS}/${id}`,{headers:{Authorization: token,'content-language': lang}});
			return response.data;
		},
		async getAll(token:string,lang:queryLang="all"):Promise<GetAllBrands>{
			const response = await websiteApi.get(`${ENDPOINTS.BRANDS}`,{headers:{Authorization: token,'content-language':lang}});
			return response.data;
		},
		async create(token:string,brandData:CreateBrand):Promise<CreateBrandResponse>{
			const response = await websiteApi.post(ENDPOINTS.BRANDS,brandData,{headers:{Authorization: token, "content-language":"all"}});
			return response.data;
		},
		async delete(token:string,id:Id):Promise<DeleteResponse>{
			const response = await websiteApi.delete(`${ENDPOINTS.BRANDS}/${id}`,{headers:{Authorization:token}});
			return response.data;
		},
		async update(token:string,newData:UpdateBrand,id:Id,lang:queryLang="all"):Promise<UpdateBrandResponse>{
			const response = await websiteApi.put(`${ENDPOINTS.BRANDS}/${id}`,newData,{headers:{Authorization:token,'content-language':lang}});
			return response.data;
		}
	},
	floors:{
		async create(token:string,floorData:CreateFloor):Promise<CreateFloorResponse>{
			const response = await websiteApi.post(ENDPOINTS.FLOORS,floorData,{headers:{Authorization: token, "content-language":"all"}});
			return response.data;
		},
		async getAll(token:string,lang:queryLang="all"):Promise<GetAllFloors>{
			const response = await websiteApi.get(`${ENDPOINTS.FLOORS}`,{headers:{Authorization: token,'content-language':lang}});
			return response.data;
		},
		async getOne(token:string,id:Id,lang:queryLang="all"):Promise<GetOneFloor>{
			const response = await websiteApi.get(`${ENDPOINTS.FLOORS}/${id}`,{headers:{Authorization: token,'content-language': lang}});
			return response.data;
		},
		async update(token:string,newData:UpdateFloor,id:Id,lang:queryLang="all"):Promise<UpdateFloorResponse>{
			const response = await websiteApi.put(`${ENDPOINTS.FLOORS}/${id}`,newData,{headers:{Authorization:token,'content-language':lang}});
			return response.data;
		},
		async delete(token:string,id:Id):Promise<DeleteResponse>{
			const response = await websiteApi.delete(`${ENDPOINTS.FLOORS}/${id}`,{headers:{Authorization:token}});
			return response.data;
		}
	},
	doors:{
		async create(token:string,doorData:CreateDoor):Promise<CreateDoorResponse>{
			const response = await websiteApi.post(ENDPOINTS.DOORS,doorData,{headers:{Authorization: token, "content-language":"all"}});
			return response.data;
		},
		async getAll(token:string,lang:queryLang="all"):Promise<GetAllDoors>{
			const response = await websiteApi.get(`${ENDPOINTS.DOORS}`,{headers:{Authorization: token,'content-language':lang}});
			return response.data;
		},
		async getOne(token:string,id:Id,lang:queryLang="all"):Promise<GetOneDoor>{
			const response = await websiteApi.get(`${ENDPOINTS.DOORS}/${id}`,{headers:{Authorization: token,'content-language': lang}});
			return response.data;
		},
		async update(token:string,newData:UpdateDoor,id:Id,lang:queryLang="all"):Promise<UpdateFloorResponse>{
			const response = await websiteApi.put(`${ENDPOINTS.DOORS}/${id}`,newData,{headers:{Authorization:token,'content-language':lang}});
			return response.data;
		},
		async delete(token:string,id:Id):Promise<DeleteResponse>{
			const response = await websiteApi.delete(`${ENDPOINTS.DOORS}/${id}`,{headers:{Authorization:token}});
			return response.data;
		}
	},
	walls:{
		async create(token:string,wallData:CreateWall):Promise<CreateWallResponse>{
			const response = await websiteApi.post(ENDPOINTS.WALLS,wallData,{headers:{Authorization: token, "content-language":"all"}});
			return response.data;
		},
		async getAll(token:string,lang:queryLang="all"):Promise<GetAllWalls>{
			const response = await websiteApi.get(`${ENDPOINTS.WALLS}`,{headers:{Authorization: token,'content-language':lang}});
			return response.data;
		},
		async getOne(token:string,id:Id,lang:queryLang="all"):Promise<GetOneWall>{
			const response = await websiteApi.get(`${ENDPOINTS.WALLS}/${id}`,{headers:{Authorization: token,'content-language': lang}});
			return response.data;
		},
		async update(token:string,newData:UpdateWall,id:Id,lang:queryLang="all"):Promise<UpdateWallResponse>{
			const response = await websiteApi.put(`${ENDPOINTS.WALLS}/${id}`,newData,{headers:{Authorization:token,'content-language':lang}});
			return response.data;
		},
		async delete(token:string,id:Id):Promise<DeleteResponse>{
			const response = await websiteApi.delete(`${ENDPOINTS.WALLS}/${id}`,{headers:{Authorization:token}});
			return response.data;
		}
	},
	windows:{
		async create(token:string,windowData:CreateWindow):Promise<CreateWindowResponse>{
			const response = await websiteApi.post(ENDPOINTS.WINDOWS,windowData,{headers:{Authorization: token, "content-language":"all"}});
			return response.data;
		},
		async getAll(token:string,lang:queryLang="all"):Promise<GetAllWindows>{
			const response = await websiteApi.get(`${ENDPOINTS.WINDOWS}`,{headers:{Authorization: token,'content-language':lang}});
			return response.data;
		},
		async getOne(token:string,id:Id,lang:queryLang="all"):Promise<GetOneWindow>{
			const response = await websiteApi.get(`${ENDPOINTS.WINDOWS}/${id}`,{headers:{Authorization: token,'content-language': lang}});
			return response.data;
		},
		async update(token:string,newData:UpdateWindow,id:Id,lang:queryLang="all"):Promise<UpdateWindowResponse>{
			const response = await websiteApi.put(`${ENDPOINTS.WINDOWS}/${id}`,newData,{headers:{Authorization:token,'content-language':lang}});
			return response.data;
		},
		async delete(token:string,id:Id):Promise<DeleteResponse>{
			const response = await websiteApi.delete(`${ENDPOINTS.WINDOWS}/${id}`,{headers:{Authorization:token}});
			return response.data;
		}
	},
	properties:{
		async create(token:string,windowData:CreateProperty):Promise<CreatePropertyResponse>{
			const response = await websiteApi.post(ENDPOINTS.PROPERTIES,windowData,{headers:{Authorization: token, "content-language": "all"}});
			return response.data;
		},
		async getAll(token:string,lang:queryLang="all"):Promise<GetAllProperties>{
			const response = await websiteApi.get(`${ENDPOINTS.PROPERTIES}`,{headers:{Authorization: token,'content-language':lang}});
			return response.data;
		},
		async getAllByProject(token:string,projectId:Id,lang:queryLang="all"):Promise<GetAllProperties>{
			const response = await websiteApi.get(`${ENDPOINTS.PROPERTIES}`,{params:{project:projectId},headers:{Authorization: token,'content-language':lang}});
			return response.data;
		},
		async getOne(token:string,id:Id,lang:queryLang="all"):Promise<GetOneProperty>{
			const response = await websiteApi.get(`${ENDPOINTS.PROPERTIES}/${id}`,{headers:{Authorization: token,'content-language': lang}});
			return response.data;
		},
		async update(token:string,newData:UpdateProperty,id:Id,lang:queryLang="all"):Promise<UpdatePropertyResponse>{
			const response = await websiteApi.put(`${ENDPOINTS.PROPERTIES}/${id}`,newData,{headers:{Authorization:token,'content-language':lang}});
			return response.data;
		},
		async updateLocationStatus(token:string,newData:{locationId:Id,isSold:boolean},id:Id,lang:queryLang="all"):Promise<UpdatePropertyResponse>{
			const response = await websiteApi.put(`${ENDPOINTS.PROPERTIES}/${id}/locationStatus`,newData,{headers:{Authorization:token,'content-language':lang}});
			return response.data;
		},
		async delete(token:string,id:Id):Promise<DeleteResponse>{
			const response = await websiteApi.delete(`${ENDPOINTS.PROPERTIES}/${id}`,{headers:{Authorization:token}});
			return response.data;
		}
	},
	roles:{
		async create(token:string,roleData:CreateRole):Promise<CreateRoleResponse>{
			const response = await websiteApi.post(ENDPOINTS.ROLES,roleData,{headers:{Authorization: token, "content-language":"all"}});
			return response.data;
		},
		async getAll(token:string,lang:queryLang="all"):Promise<GetAllRoles>{
			const response = await websiteApi.get(`${ENDPOINTS.ROLES}`,{headers:{Authorization: token,'content-language':lang}});
			return response.data;
		},
		async getOne(token:string,id:Id,lang:queryLang="all"):Promise<GetOneRole>{
			const response = await websiteApi.get(`${ENDPOINTS.ROLES}/${id}`,{headers:{Authorization: token,'content-language': lang}});
			return response.data;
		},
		async update(token:string,newData:UpdateRole,id:Id,lang:queryLang="all"):Promise<UpdateRoleResponse>{
			const response = await websiteApi.put(`${ENDPOINTS.ROLES}/${id}`,newData,{headers:{Authorization:token,'content-language':lang}});
			return response.data;
		},
		async delete(token:string,id:Id):Promise<DeleteResponse>{
			const response = await websiteApi.delete(`${ENDPOINTS.ROLES}/${id}`,{headers:{Authorization:token}});
			return response.data;
		}
	},
	categories:{
		async create(token:string,categoryData:CreateCategory):Promise<CreateCategoryResponse>{
			const response = await websiteApi.post(ENDPOINTS.CATEGORIES,categoryData,{headers:{Authorization: token, "content-language":"all"}});
			return response.data;
		},
		async getAll(token:string,lang:queryLang="all"):Promise<GetAllCategories>{
			const response = await websiteApi.get(`${ENDPOINTS.CATEGORIES}`,{headers:{Authorization: token,'content-language':lang}});
			return response.data;
		},
		async getAllWithSubCategories(token:string, lang:queryLang = "all"){
			const categories = await Api.categories.getAll(token);
			const allSubCategories = await Promise.all(categories.map(category => Api.subCategories.getAll(token, category._id)))
			return categories.map((category, index) => ({...category, subCategories: allSubCategories[index]}));
		},
		async getOne(token:string,id:Id,lang:queryLang="all"):Promise<GetOneCategory>{
			const response = await websiteApi.get(`${ENDPOINTS.CATEGORIES}/${id}`,{headers:{Authorization: token,'content-language': lang}});
			return response.data;
		},
		async update(token:string,newData:UpdateCategory,id:Id,lang:queryLang="all"):Promise<UpdateCategoryResponse>{
			const response = await websiteApi.put(`${ENDPOINTS.CATEGORIES}/${id}`,newData,{headers:{Authorization:token,'content-language':lang}});
			return response.data;
		},
		async delete(token:string,id:Id):Promise<DeleteResponse>{
			const response = await websiteApi.delete(`${ENDPOINTS.CATEGORIES}/${id}`,{headers:{Authorization:token}});
			return response.data;
		}
	},
	subCategories:{
		async create(token:string,subCategoryData:CreateSubCategory,categoryId:Id):Promise<CreateSubCategoryResponse>{
			const response = await websiteApi.post(`${ENDPOINTS.CATEGORIES}/${categoryId}${ENDPOINTS.SUBCATEGORIES}`,subCategoryData,{headers:{Authorization: token, "content-language":"all"}});
			return response.data;
		},
		async getAll(token:string,categoryId:Id,lang:queryLang="all"):Promise<GetAllSubCategories>{
			const response = await websiteApi.get(`${ENDPOINTS.CATEGORIES}/${categoryId}${ENDPOINTS.SUBCATEGORIES}`,{headers:{Authorization: token,'content-language':lang}});
			return response.data;
		},
		async getOne(token:string,id:Id,categoryId:Id,lang:queryLang="all"):Promise<GetOneSubCategory>{
			const response = await websiteApi.get(`${ENDPOINTS.CATEGORIES}/${categoryId}${ENDPOINTS.SUBCATEGORIES}/${id}`,{headers:{Authorization: token,'content-language': lang}});
			return response.data;
		},
		async update(token:string,newData:UpdateSubCategory,id:Id,categoryId:Id,lang:queryLang="all"):Promise<UpdateSubCategoryResponse>{
			const response = await websiteApi.put(`${ENDPOINTS.CATEGORIES}/${categoryId}${ENDPOINTS.SUBCATEGORIES}/${id}`,newData,{headers:{Authorization:token,'content-language':lang}});
			return response.data;
		},
		async delete(token:string,id:Id,categoryId:Id):Promise<DeleteResponse>{
			const response = await websiteApi.delete(`${ENDPOINTS.CATEGORIES}/${categoryId}${ENDPOINTS.SUBCATEGORIES}/${id}`,{headers:{Authorization:token}});
			return response.data;
		}
	},
	countries:{
		async create(token:string,countryData:CreateCountry):Promise<CreateCountryResponse>{
			const response = await websiteApi.post(ENDPOINTS.COUNTIRES,countryData,{headers:{Authorization: token, "content-language":"all"}});
			return response.data;
		},
		async getAll(token:string,lang:queryLang="all"):Promise<GetAllCountries>{
			const response = await websiteApi.get(`${ENDPOINTS.COUNTIRES}`,{headers:{Authorization: token,'content-language':lang}});
			return response.data;
		},
		async getAllWithCities(token:string, lang:queryLang = "all"){
			const countries = await Api.countries.getAll(token);
			const allCities = await Promise.all(countries.map(country => Api.cities.getAll(token, country._id)))
			return countries.map((country, index) => ({...country, cities: allCities[index]}));
		},
		async getOne(token:string,id:Id,lang:queryLang="all"):Promise<GetOneCountry>{
			const response = await websiteApi.get(`${ENDPOINTS.COUNTIRES}/${id}`,{headers:{Authorization: token,'content-language': lang}});
			return response.data;
		},
		async update(token:string,newData:UpdateCountry,id:Id,lang:queryLang="all"):Promise<UpdateCountryResponse>{
			const response = await websiteApi.put(`${ENDPOINTS.COUNTIRES}/${id}`,newData,{headers:{Authorization:token,'content-language':lang}});
			return response.data;
		},
		async delete(token:string,id:Id):Promise<DeleteResponse>{
			const response = await websiteApi.delete(`${ENDPOINTS.COUNTIRES}/${id}`,{headers:{Authorization:token}});
			return response.data;
		}
	},
	cities:{
		async create(token:string,cityData:CreateCity,countryId:Id):Promise<CreateCityResponse>{
			const response = await websiteApi.post(`${ENDPOINTS.COUNTIRES}/${countryId}${ENDPOINTS.CITIES}`,cityData,{headers:{Authorization: token, "content-language":"all"}});
			return response.data;
		},
		async getAll(token:string,countryId:Id,lang:queryLang="all"):Promise<GetAllCities>{
			const response = await websiteApi.get(`${ENDPOINTS.COUNTIRES}/${countryId}${ENDPOINTS.CITIES}`,{headers:{Authorization: token,'content-language':lang}});
			return response.data;
		},
		async getAllWithDistricts(token:string, countryId:Id, lang:queryLang = "all"){
			const cities = await Api.cities.getAll(token,countryId);
			const allDistricts = await Promise.all(cities.map(city => Api.districts.getAll(token, countryId, city._id)))
			return cities.map((city, index) => ({...city, cities: allDistricts[index]}));
		},
		async getOne(token:string,id:Id,countryId:Id,lang:queryLang="all"):Promise<GetOneCity>{
			const response = await websiteApi.get(`${ENDPOINTS.COUNTIRES}/${countryId}${ENDPOINTS.CITIES}/${id}`,{headers:{Authorization: token,'content-language': lang}});
			return response.data;
		},
		async update(token:string,newData:UpdateCity,id:Id,countryId:Id,lang:queryLang="all"):Promise<UpdateCityResponse>{
			const response = await websiteApi.put(`${ENDPOINTS.COUNTIRES}/${countryId}${ENDPOINTS.CITIES}/${id}`,newData,{headers:{Authorization:token,'content-language':lang}});
			return response.data;
		},
		async delete(token:string,id:Id,countryId:Id):Promise<DeleteResponse>{
			const response = await websiteApi.delete(`${ENDPOINTS.COUNTIRES}/${countryId}${ENDPOINTS.CITIES}/${id}`,{headers:{Authorization:token}});
			return response.data;
		}
	},
	districts:{
		async create(token:string,districtData:CreateDistrict,countryId:Id,cityId:Id):Promise<CreateDistrictResponse>{
			const response = await websiteApi.post(`${ENDPOINTS.COUNTIRES}/${countryId}${ENDPOINTS.CITIES}/${cityId}${ENDPOINTS.DISTRICTS}`,districtData,{headers:{Authorization: token, "content-language":"all"}});
			return response.data;
		},
		async getAll(token:string,countryId:Id,cityId:Id,lang:queryLang="all"):Promise<GetAllDistricts>{
			const response = await websiteApi.get(`${ENDPOINTS.COUNTIRES}/${countryId}${ENDPOINTS.CITIES}/${cityId}${ENDPOINTS.DISTRICTS}`,{headers:{Authorization: token,'content-language':lang}});
			return response.data;
		},
		async getOne(token:string,id:Id,countryId:Id,cityId:Id,lang:queryLang="all"):Promise<GetOneDistrict>{
			const response = await websiteApi.get(`${ENDPOINTS.COUNTIRES}/${countryId}${ENDPOINTS.CITIES}/${cityId}${ENDPOINTS.DISTRICTS}/${id}`,{headers:{Authorization: token,'content-language': lang}});
			return response.data;
		},
		async update(token:string,newData:UpdateDistrict,id:Id,countryId:Id,cityId:Id,lang:queryLang="all"):Promise<UpdateDistrictResponse>{
			const response = await websiteApi.put(`${ENDPOINTS.COUNTIRES}/${countryId}${ENDPOINTS.CITIES}/${cityId}${ENDPOINTS.DISTRICTS}/${id}`,newData,{headers:{Authorization:token,'content-language':lang}});
			return response.data;
		},
		async delete(token:string,id:Id,countryId:Id,cityId:Id):Promise<DeleteResponse>{
			const response = await websiteApi.delete(`${ENDPOINTS.COUNTIRES}/${countryId}${ENDPOINTS.CITIES}/${cityId}${ENDPOINTS.DISTRICTS}/${id}`,{headers:{Authorization:token}});
			return response.data;
		}
	},
	permissions:{
		async getAll(token:string,lang:queryLang="all"):Promise<GetAllPermissions>{
			const response = await websiteApi.get(ENDPOINTS.PERMISSIONS,{headers:{Authorization: token}});
			return response.data;
		},
		async getOne(token:string,id:Id,lang:queryLang="all"):Promise<Permission>{
			const response = await websiteApi.get(`${ENDPOINTS.PERMISSIONS}/${id}`,{headers:{Authorization: token,'content-language': lang}});
			return response.data;
		},
	},
	async getDictionary(lang:language){
		return await {}
	},
	async getProperty(id:string){
		return await {}
	},
}

